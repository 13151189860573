<template>
  <modify-apply-block
    data-type="worker_certificate_doctor_pra"
    ref="worker_certificate_doctor_pra_p"
    :getTitle="getTitle"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :form-parms-add="formParmsAdd"
    :dataFileParm="{docName: '专业技术资格证书', isDoc: true }"
    block-name="执业证书"
    :onChange="onChange"
    @dataFileUpdate="dataFileUpdate"
    :init-add-form-data="initAddFormData"
    ref-name="worker_certificate_doctor_pra"
    :worker-id="workerId">
    <certiticateChangeLog
      v-if="openDialogLog"
      :workerCertificateId="workerCertificateId"
      :openDialogLog="openDialogLog"
      @handleClose="openDialogLog = false">
    </certiticateChangeLog>
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import CertiticateChangeLog from '../../worker/worker/certiticateChangeLog'

import {
  workerCertificateRequest
} from '../../../api'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    ModifyApplyBlock,
    CertiticateChangeLog
  },
  created () {
    this.workerId = this.$router.history.current.query.workerId
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['worker_certificate_doctor_pra_p'].openForm) {
      this.$refs['worker_certificate_doctor_pra_p'].openForm = false
      next(false)
    } else {
      next()
    }
  },
  data () {
    return {
      workerId: null,
      openDialogLog: false,
      workerCertificateId: null
    }
  },
  methods: {
    async getEdit () {
      if (this.workerId) {
        return await workerCertificateRequest.getEdit({
          type: 'doctor_pra',
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      return await workerCertificateRequest.saveEdit(Object.assign({}, parm, {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await workerCertificateRequest.addEdit(parm)
    },
    onChange (key, value, formData) {
      let data = {}
      if (key === 'getDate' && !formData['hireStartDate']) {
        data['hireStartDate'] = value
      }
      if (key === 'updateLog') {
        let datas = value ? JSON.parse(value) : []
        datas = datas.filter(v => ['goon', 'first'].includes(v.type))
        if (datas.length === 0) {
          data['hireStartDate'] = formData['getDate']
          data['hireEndDate'] = null
        } else {
          data['hireStartDate'] = datas[0].registDate
          data['hireEndDate'] = datas[0].registEndDate
        }
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd'})
        }
      }
      return data
    },
    async dataFileUpdate (parm, pageVm) {
      await workerCertificateRequest.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'doctor_pra'
      }
    },
    getTitle (data) {
      return (data.mType || '') + ' ' + (data.getDate ? data.getDate.slice(0, 10) : '')
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['practicing_certificate_type'],
          label: '执业证书类型',
          key: 'mType'
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '发证机关',
          key: 'issueOrg'
        },
        {
          type: 'input',
          label: '签发人',
          key: 'issueUser'
        },
        {
          type: 'datePicker',
          label: '发证日期',
          key: 'getDate'
        },
        {
          type: 'datePicker',
          label: '有效期开始时间',
          key: 'hireStartDate'
        },
        {
          type: 'datePicker',
          label: '有效期结束时间',
          key: 'hireEndDate'
        },
        {
          type: 'input',
          label: '执业地点',
          key: 'practiceAddress'
        },
        {
          type: 'input',
          label: '执业类别',
          key: 'practiceType'
        },
        {
          type: 'input',
          label: '执业范围',
          key: 'practiceRange'
        }]
        return data
      }
    }
  }
}
</script>
